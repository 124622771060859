import {
  AddHomeWorkOutlinedIcon,
  Box,
  CircularProgress,
  ContentPasteSearchOutlinedIcon,
  CustomAlert,
  DataSaverOnOutlinedIcon,
  DifferenceOutlinedIcon,
  enerbitColors,
  EventOutlinedIcon,
  LocationCityIcon,
  MapsHomeWorkOutlinedIcon,
  ModeOfTravelOutlinedIcon,
  PersonSearchOutlinedIcon,
  SpeakerNotesOutlinedIcon,
  SupervisorAccountOutlinedIcon,
  theme,
  Typography,
  VillaOutlinedIcon,
} from "@enerbit/base";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SectionCardInformation from "../../helpers/CardInformationGeneral/SectionCardInformation";
import { StateStorage } from "../../models/StateStorage";
import { getFrontiers } from "../../store/actions/assignees/assignees.actions";
import {
  cleanServiceReconnectMessage,
  cleanStoreInformation,
  getAssetOwnerships,
  getEssById,
  getEstates,
  getFixedService,
  getSuiSocialStratums,
  getZones,
  resetAndSaveTab,
  setIndexTab,
} from "../../store/slices/informationSlice";
import { AppDispatch } from "../../store/store";

import { RolesName } from "../../helpers/roles/rolesName";

import moment from "moment";
import { navigateToUrl } from "single-spa";
import SectionCardTasks from "../../helpers/CardInformationTasks/SectionCardTasks";
import { CustomizedSelect } from "../../helpers/CustomizedSelect/CustomizedSelect";
import Loader from "../../helpers/Loader/Loader";
import { LoadingIndicator } from "../../helpers/Loader/LoadingIndicator";
import { ShowUserInformation } from "../../helpers/ShowUserInformation/ShowUserInformation";

import { AppUsers } from "../../models/accounts/ServicesAccount";
import {
  getAppUsers,
  getServiceAccountsRelationships,
} from "../../store/actions/account/account.actions";
import {
  cleanStoreAccount,
  setCurrentUser,
} from "../../store/slices/accountSlice";
import { changeActivePropertyName } from "../../utils/changeActivePropertyName";
import { serviceActive } from "../../utils/connectionCardUtils";
import { validateTypeFrontier } from "../../utils/frontierUtils";
import { ConnectionCard } from "./Components/ConnectionsCards/ConnectionCard";
import { ConnectionCardActive } from "./Components/ConnectionsCards/ConnectionCardActive";
import { ConnectionCardInactive } from "./Components/ConnectionsCards/ConnectionCardInactive";
import { EnerbitServiceAgreementCard } from "./Components/EnerbitServiceAgreementCard";
import ReconnectServiceDialog from "./reconect-service/ReconnectServiceDialog";

const renderLoader = (isLoading: boolean, message: string) => {
  return isLoading && <Loader message={message} />;
};

const LeftMenu = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  //Traer id del servicio elegido
  const { id } = useParams<string>();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");

  //Traer estados del store
  const {
    isLoadingService,
    isSuccessCregSubscriberPatch,
    serviceError,
    serviceReconnectMessage,
    isSuccessServiceReconnect,
    isLoadingServiceReconnect,
    service,
    serviceStatus,
    serviceErrorObject,
    isLoadingServiceStatus,
    serviceList,
    isLoadingServiceList,
    serviceListError,
  } = useSelector((state: StateStorage) => state.information);

  const {
    usersServiceAccount,
    appUsers,
    appUser,
    isLoadingUsersServiceAccount,
    selectedAppUser,
    isLoadingAppUsers,
    hasErrorAppUsers,
  } = useSelector((state: StateStorage) => state.account);

  const { enerbitFrontiers, enerbitFrontiersError } = useSelector(
    (state: StateStorage) => state.assignees
  );

  useEffect(() => {
    // Limpiar al montar componente
    dispatch(cleanStoreInformation());
    dispatch(cleanStoreAccount());

    return () => {
      // Limpiar al desmontar componente
      dispatch(cleanStoreInformation());
      dispatch(cleanStoreAccount());
    };
  }, [dispatch, navigate]);

  const hasAppUsers = appUsers && appUsers.length > 0;

  const findUser = (appUsers: AppUsers, userId: string | null) => {
    return appUsers.find((user: { user_id: string; rol: RolesName }) =>
      // Si se proporciona un userId, buscar el usuario con ese userId
      userId
        ? user.user_id === userId
        : // Si no se proporciona un userId, buscar el usuario con rol de propietario
          user.rol === RolesName.owner
    );
  };

  const getStateId = async (id: string) => {
    await dispatch(getEssById(id));
    dispatch(getEstates());
    dispatch(getFixedService(id));
  };

  useEffect(() => {
    dispatch(cleanStoreInformation());
    dispatch(resetAndSaveTab());

    if (id) {
      getStateId(id);
    }
    dispatch(getZones());
    dispatch(getSuiSocialStratums());
    dispatch(getAssetOwnerships());
  }, [dispatch, id]);

  useEffect(() => {
    if (
      service !== undefined &&
      service !== null &&
      JSON.stringify(service) !== "{}"
    ) {
      dispatch(getFrontiers(service?.measurement_point_id));
    }
  }, [dispatch, service?.measurement_point_id]);

  //Refrescar los datos al hacer patch o al ingresar con otro cliente
  useEffect(() => {
    if (isSuccessCregSubscriberPatch && id) {
      dispatch(getEssById(id));
    }
  }, [isSuccessCregSubscriberPatch, id]);

  useEffect(() => {
    if (service?.service_account_id) {
      dispatch(getServiceAccountsRelationships(service?.service_account_id));
    }
  }, [dispatch, service?.service_account_id]);

  useEffect(() => {
    if (usersServiceAccount) {
      dispatch(getAppUsers(usersServiceAccount.items ?? []));
    }
  }, [dispatch, usersServiceAccount]);

  useEffect(() => {
    if (hasAppUsers) {
      const user = findUser(appUsers, userId);
      dispatch(
        setCurrentUser({
          appUsers: user,
        })
      );
    }
  }, [hasAppUsers, dispatch]);

  // useEffect(() => {
  //   if (service && userId) {
  //     console.log(
  //       "service?.service_account_id",
  //       service?.service_account_id,
  //       "userId",
  //       userId
  //     );
  //     dispatch(
  //       getServiceRelationships({
  //         service_account_id: service?.service_account_id,
  //         user_id: userId,
  //       })
  //     );
  //   }
  // }, [dispatch, service]);

  const loaderUsersServiceAccount = renderLoader(
    isLoadingUsersServiceAccount,
    "Cargando de usuarios"
  );
  const loaderAppUsers = renderLoader(
    isLoadingAppUsers,
    "Cargando de usuarios"
  );

  const meterConnectionStatus = isLoadingServiceStatus
    ? LoadingIndicator
    : serviceStatus.relay_status === "ON"
    ? "Sí"
    : serviceErrorObject?.message;

  return (
    <Box
      bgcolor={"white"}
      sx={{ height: "100%", boxShadow: "2px 1px 9px 0px rgba(250,250,250,1)" }}
    >
      <Box sx={{ padding: "1.6rem 1.25rem 1.25rem 2.25rem", width: "354px" }}>
        <Box>
          {" "}
          {isLoadingService && (
            <Box display="flex" gap="1rem" alignItems="center">
              <Typography color={theme.palette.primary.main}>
                Cargando Información básica y técnica
              </Typography>{" "}
              <CircularProgress />{" "}
            </Box>
          )}
          {loaderUsersServiceAccount}
          {!isLoadingUsersServiceAccount && (
            <>
              {loaderAppUsers}
              {!isLoadingAppUsers && hasAppUsers && Array.isArray(appUsers) ? (
                <>
                  <CustomizedSelect appUsers={appUsers} appUser={appUser} />
                  {!isLoadingService && (
                    <>
                      {service && !serviceError && (
                        <>
                          {service.tags && (
                            <SectionCardTasks
                              titleText={"Etiquetas del servicio"}
                              titleColor={theme.palette.primary.main}
                              bodyColor={enerbitColors?.information[800]}
                              data={service.tags}
                              IconTitle={LocalOfferOutlinedIcon}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                  {selectedAppUser && (
                    <ShowUserInformation
                      selectedAppUser={selectedAppUser}
                      hasAppUsers={hasAppUsers}
                    />
                  )}
                  <Box sx={{ margin: "1rem" }}></Box>
                </>
              ) : (
                hasErrorAppUsers && (
                  <CustomAlert
                    severity="error"
                    text={hasErrorAppUsers}
                    onClose={() => null}
                  />
                )
              )}
            </>
          )}
          {!isLoadingService && (
            <>
              {service && !serviceError ? (
                <>
                  {service.meter?.id && serviceActive(service.ended_at) ? (
                    <ConnectionCard />
                  ) : service.meter === null &&
                    serviceActive(service.ended_at) ? (
                    <ConnectionCardActive />
                  ) : (
                    <ConnectionCardInactive />
                  )}
                  <Box sx={{ margin: "1rem" }}></Box>
                  <SectionCardInformation
                    titleText={"Información del servicio"}
                    titleColor={theme.palette.secondary.main}
                    bodyColor={enerbitColors?.neutral[700]}
                    background={"#FFF1E6"}
                    IconTitle={ContentPasteSearchOutlinedIcon}
                    showButtonTop={true}
                    onClick={() => {
                      dispatch(setIndexTab(2));
                    }}
                    data={[
                      {
                        label: "ID del servicio de energía:",
                        value: service.id,
                        Icon: PersonSearchOutlinedIcon,
                      },
                      {
                        label: "NIU:",
                        value: service?.creg_subscriber?.niu,
                        Icon: SpeakerNotesOutlinedIcon,
                      },
                      {
                        label: "ID punto de medida:",
                        value: service.measurement_point_id,
                        Icon: PersonSearchOutlinedIcon,
                      },
                      {
                        label: "Exento de contribución:",
                        value: service?.service_agreement?.contribution_flag
                          ? "No"
                          : "Sí",
                        Icon: DifferenceOutlinedIcon,
                      },
                      {
                        label: "Serial del medidor:",
                        value: service?.meter?.serial ?? "Sin medidor",
                        showButtonItem: {
                          shouldShowButton: service?.meter?.serial
                            ? true
                            : false,
                          onClick: () => {
                            navigateToUrl(
                              `/#/reading-records/detail/${service?.meter?.serial}`
                            );
                          },
                        },
                        Icon: AddHomeWorkOutlinedIcon,
                      },
                      {
                        label: "Meter ID:",
                        value: service?.meter?.id ?? "Sin medidor",
                        Icon: AddHomeWorkOutlinedIcon,
                      },
                      {
                        label: "¿Medidor conectado?:",
                        value: service?.meter?.id
                          ? meterConnectionStatus
                          : "No",
                        showButtonItem: {
                          shouldShowButton:
                            serviceStatus.relay_status === "ON" ? true : false,
                          onClick: () => {
                            navigateToUrl(
                              `/#/reading-records/detail/${service?.meter?.serial}`
                            );
                          },
                        },
                        Icon: VpnKeyOutlinedIcon,
                      },
                      ...(service.m_counter
                        ? [
                            {
                              label: "Factor reactiva (M):",
                              value: service.m_counter,
                              Icon: SpeakerNotesOutlinedIcon,
                            },
                          ]
                        : []),
                      {
                        label: "Nivel de tensión:",
                        value:
                          service?.creg_subscriber?.voltage_level?.sui_code,
                        Icon: AccountTreeOutlinedIcon,
                      },
                      {
                        label: "Propiedad del activo:",
                        value: changeActivePropertyName(
                          service?.creg_subscriber?.asset_ownership?.sui_code
                        ),
                        Icon: SupervisorAccountOutlinedIcon,
                      },
                      {
                        label: "Fecha inicio servicio:",
                        value: moment(service?.started_at).format("YYYY-MM-DD"),
                        Icon: EventOutlinedIcon,
                      },
                      // {
                      //   label: "Servicios:",
                      //   value: (
                      //     <ServicesItems
                      //       serviceList={serviceList}
                      //       isLoadingServiceList={isLoadingServiceList}
                      //       serviceListError={serviceListError}
                      //     />
                      //   ),
                      //   Icon: DifferenceOutlinedIcon,
                      // },
                    ]}
                  />
                  <Box sx={{ margin: "1rem" }}></Box>
                  <SectionCardInformation
                    titleText={"Información del predio"}
                    titleColor={enerbitColors?.primary[600]}
                    bodyColor={enerbitColors?.neutral[700]}
                    background={enerbitColors?.primary[200]}
                    IconTitle={LocationCityIcon}
                    showButtonTop={true}
                    onClick={() => {
                      dispatch(setIndexTab(2));
                    }}
                    data={[
                      {
                        label: "Dirección:",
                        value: service?.estate?.address,
                        Icon: MapsHomeWorkOutlinedIcon,
                      },
                      {
                        label: "Departamento:",
                        value: service?.estate?.state,
                        Icon: VillaOutlinedIcon,
                      },
                      {
                        label: "Ciudad:",
                        value: service?.estate?.city,
                        Icon: VillaOutlinedIcon,
                      },
                      {
                        label: "Estrato:",
                        value: `${service?.creg_subscriber?.sui_social_stratum?.sui_code} ${service?.creg_subscriber?.sui_social_stratum?.description}`,
                        Icon: DataSaverOnOutlinedIcon,
                      },
                    ]}
                  />
                  <Box sx={{ margin: "1rem" }}></Box>
                  <EnerbitServiceAgreementCard
                    enerbitElectricitySupplyService={
                      service?.enerbit_electricity_supply_service
                    }
                  />
                  <Box sx={{ margin: "1rem" }}></Box>

                  <SectionCardInformation
                    titleText={"Información de la frontera"}
                    titleColor={enerbitColors?.information.main}
                    bodyColor={enerbitColors?.neutral[700]}
                    background={enerbitColors?.information[100]}
                    IconTitle={ModeOfTravelOutlinedIcon}
                    showButtonTop={true}
                    onClick={() => {
                      dispatch(setIndexTab(2));
                    }}
                    errorMessage={enerbitFrontiersError}
                    data={validateTypeFrontier(enerbitFrontiers)}
                  />
                </>
              ) : (
                serviceError && (
                  <CustomAlert
                    severity="error"
                    text={serviceError}
                    onClose={() => null}
                  />
                )
              )}
            </>
          )}
        </Box>
        <ReconnectServiceDialog />

        {!isLoadingServiceReconnect && serviceReconnectMessage && (
          <CustomAlert
            severity={isSuccessServiceReconnect ? "success" : "error"}
            text={serviceReconnectMessage}
            onClose={() => dispatch(cleanServiceReconnectMessage())}
            sx={{ mt: "20px" }}
          />
        )}
      </Box>
    </Box>
  );
};

export default LeftMenu;
