import { EnerbitPagination, api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { StateStorage } from "../../../models/StateStorage";
import {
  CreateServiceEnerbitRequest,
  CreateServiceEnerbitResponse,
} from "../../../models/electricity-supply-services/CreateServiceEnerbit";
import { EnerbitServiceAgreement } from "../../../models/electricity-supply-services/EnerbitESS";
import { EnerbitServiceAgreementsRelationships } from "../../../models/electricity-supply-services/EnerbitServiceAgreementsRelationships";
import { IServiceRelationships } from "../../../models/electricity-supply-services/IServiceRelationships";
import { getEssById, updateServiceVar } from "../../slices/informationSlice";
export const getEnerbitElectricitySupplyService = createAsyncThunk(
  //action type string
  "[Electricity Supply Service] Get Enerbit Electricity Supply Service Agreements",
  //callback function
  async (
    _payload,
    _thunkAPI
  ): Promise<EnerbitPagination<EnerbitServiceAgreement> | null> => {
    const { data, status } = await api.get(
      `electricity-supply-service/manager/enerbit-electricity-supply-service-agreements/`,
      {
        params: {
          size: 300,
        },
      }
    );
    if (status === 200 && data) {
      return data;
    }
    throw new Error("Data not found");
  }
);

export interface CreateEnerbitServiceAgreementRelationship {
  electricity_supply_service_id: string;
  to_service_agreement_id: string;
  started_at: string;
}

export interface CreateEnerbitServiceAgreementRelationshipResponse {
  user_id: string;
  user_type: string;
  enerbit_electricity_supply_service_id: string;
  enerbit_electricity_supply_service_agreement_id: string;
  fixed_enerbit_electricity_supply_services_id: string;
  started_at: string;
  ended_at: string;
  id: string;
  created_at: string;
  updated_at: string;
}

export interface AssignPlanEnerbitServiceRequest {
  enerbit_electricity_supply_service_id: string;
  enerbit_electricity_supply_service_agreement_id: string;
  fixed_enerbit_electricity_supply_services_id: string;
  started_at: string;
}

export interface AssignPlanEnerbitServiceResponse {
  user_id: string;
  enerbit_electricity_supply_service_id: string;
  enerbit_electricity_supply_service_agreement_id: string;
  fixed_enerbit_electricity_supply_services_id: string;
  started_at: string;
  ended_at: string;
  id: string;
  created_at: string;
  updated_at: string;
}

const updateService = (
  state: StateStorage,
  enerbit_electricity_supply_service_agreement_id: string
) => {
  if (state.information.service != undefined) {
    let service = { ...state.information.service };
    if (
      service?.enerbit_electricity_supply_service &&
      state.planManagement.paginationEnerbitServiceAgreements
    ) {
      let plan =
        state.planManagement.paginationEnerbitServiceAgreements?.items.find(
          (e) => e.id === enerbit_electricity_supply_service_agreement_id
        );
      service.enerbit_electricity_supply_service = {
        ...service.enerbit_electricity_supply_service,
        enerbit_service_agreement: plan!,
      };
      return service;
    }
  }
};

export const postEnerbitServiceAgreementsRelationshipsChangePlan =
  createAsyncThunk(
    //action type string
    "[Electricity Supply Service] Post Enerbit Service Agreements Relationships Change Plan",
    //callback function
    async (
      payload: CreateEnerbitServiceAgreementRelationship,
      thunkAPI
    ): Promise<EnerbitPagination<CreateEnerbitServiceAgreementRelationshipResponse> | null> => {
      const { data, status } = await api.post(
        `electricity-supply-service/manager/enerbit-service-agreements-relationships/change-plan/`,
        payload
      );
      if (status === 200 && data) {
        let state = thunkAPI.getState() as StateStorage;
        const service = updateService(state, payload.to_service_agreement_id);
        if (service) thunkAPI.dispatch(updateServiceVar(service));
        return data;
      }
      throw new Error("Data not found");
    }
  );

export const postEnerbitServiceAgreementsRelationships = createAsyncThunk(
  //action type string
  "[Electricity Supply Service] Post Enerbit Service Agreements Relationships",
  //callback function
  async (
    payload: AssignPlanEnerbitServiceRequest,
    thunkAPI
  ): Promise<EnerbitPagination<AssignPlanEnerbitServiceResponse> | null> => {
    const { data, status } = await api.post(
      `electricity-supply-service/manager/enerbit-service-agreements-relationships/`,
      payload
    );
    if (status === 200 && data) {
      let state = thunkAPI.getState() as StateStorage;
      const service = updateService(
        state,
        payload.enerbit_electricity_supply_service_agreement_id
      );
      if (service) thunkAPI.dispatch(updateServiceVar(service));
      return data;
    }
    throw new Error("Data not found");
  }
);

export const postEnerbitElectricitySupplyService = createAsyncThunk(
  //action type string
  "[Electricity Supply Service] Post Enerbit Electricity Supply Service",
  //callback function
  async (
    payload: CreateServiceEnerbitRequest,
    thunkAPI
  ): Promise<CreateServiceEnerbitResponse | null> => {
    const { data, status } = await api.post(
      `electricity-supply-service/manager/enerbit-electricity-supply-service`,
      payload
    );
    if (status === 200 && data) {
      await thunkAPI.dispatch(
        getEssById(payload.electricity_supply_service_id)
      );
      return data;
    }
    throw new Error("Data not found");
  }
);

export const getEnerbitServiceAgreementsRelationships = createAsyncThunk<
  EnerbitServiceAgreementsRelationships,
  string,
  { rejectValue: AxiosError }
>(
  "[Electricity Supply Service] Get Enerbit Service Agreements Relationships",
  async (enerbit_electricity_supply_service_id: string, thunkAPI) => {
    try {
      const response: AxiosResponse<EnerbitServiceAgreementsRelationships> =
        await (api as AxiosInstance).get(
          "/electricity-supply-service/manager/enerbit-service-agreements-relationships/?enerbit_electricity_supply_service_id=" +
            enerbit_electricity_supply_service_id +
            "&page=0&size=50"
        );
      return response.data;
    } catch (error: any) {
      console.error(error);
      const axiosError: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(axiosError);
    }
  }
);

interface ServiceRelationshipsParams {
  user_id: string;
  service_account_id: string;
}

export const getServiceRelationships = createAsyncThunk(
  "[Electricity Supply Service Manager Service Relationships] getServiceRelationships",
  async (
    {
      service_account_id,
      user_id,
    }: {
      service_account_id: string;
      user_id: string;
    },
    thunkAPI
  ) => {
    try {
      const { data }: { data: IServiceRelationships } = await (
        api as AxiosInstance
      ).get(
        `/electricity-supply-service/manager/service-relationships/?service_account_id=${service_account_id}&user_id=${user_id}`
      );
      return data;
    } catch (error: any) {
      console.error(error);
      const axiosError: AxiosError = error as AxiosError;
      return thunkAPI.rejectWithValue(axiosError.response?.data);
    }
  }
);
