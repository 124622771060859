import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { EnerbitPagination } from "@enerbit/base";
import { AxiosError } from "axios";
import { EEntity } from "../../common/enums/EServiceOrder";
import { ItemGetOrders } from "../../models/interfaces/ItemGetOrders";
import {
  IAction,
  ServicesOrdersState,
} from "../../models/states/ServiceOrdersState";
import {
  cancelServiceOrder,
  getCancelReasons,
  getServiceOrderTypes,
  getServicesOrders,
} from "../actions/service-orders/service-orders.actions";

const initialState: ServicesOrdersState = {
  serviceOrders: {
    LD: {
      items: null,
      page: 0,
      size: 0,
      total: 0,
      pages: 0,
      previous_page: 0,
      next_page: 0,
    },
    MP: {
      items: null,
      page: 0,
      size: 0,
      total: 0,
      pages: 0,
      previous_page: 0,
      next_page: 0,
    },
  },
  entity: {
    lead: EEntity.Lead,
    client: EEntity.Client,
  },
  isLoadingServiceOrders: {
    LD: false,
    MP: false,
  },
  errorServiceOrders: {
    LD: "",
    MP: "",
  },
  action: null,
  listServiceOrderTypes: [],
  loadingServiceOrderTypes: false,
  cancellationReasons: [],
  loadingCancellationReasons: false,
  errorCancellationReasons: "",
  cancellationResponsibilities: [],
  loadingCancelServiceOrder: false,
  errorCancelServiceOrder: false,
  messageErrorCancelServiceOrder: "",
  isDoneCancelServiceOrder: false,
  errorContent: null,
};

export const serviceOrdersSlice = createSlice({
  name: "serviceOrders",
  initialState,
  reducers: {
    setAction: (state, { payload }: PayloadAction<IAction | null>) => {
      state.action = payload;
    },
    selectOrder: (state, { payload }: PayloadAction<ItemGetOrders>) => {
      state.selectedOrder = payload;
    },
    cleanStoreServiceOrders: (state) => {
      return initialState;
    },
    resetStatusCancelServiceOrder: (state) => {
      state.isDoneCancelServiceOrder = false;
      state.errorCancelServiceOrder = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getServicesOrders.pending.type,
        (
          state,
          action: PayloadAction<void, string, { arg: { entity: EEntity } }>
        ) => {
          const entity: EEntity = action.meta.arg.entity;
          state.isLoadingServiceOrders[entity] = true;
          state.errorServiceOrders[entity] = "";
        }
      )
      .addCase(
        getServicesOrders.fulfilled.type,
        (
          state,
          {
            payload,
            meta,
          }: PayloadAction<
            EnerbitPagination<ItemGetOrders>,
            string,
            { arg: { entity: EEntity } }
          >
        ) => {
          const entity: EEntity = meta.arg.entity;
          const { items, pages, page, size, total, previous_page, next_page } =
            payload;
          state.serviceOrders[entity] = {
            items,
            pages,
            page,
            size,
            total,
            previous_page,
            next_page,
          };
          state.isLoadingServiceOrders[entity] = false;
        }
      )
      .addCase(
        getServicesOrders.rejected.type,
        (
          state,
          action: PayloadAction<void, string, { arg: { entity: EEntity } }>
        ) => {
          const { meta } = action;
          const entity: EEntity = meta.arg.entity;
          state.isLoadingServiceOrders[entity] = false;
          state.errorServiceOrders[entity] =
            "Ha ocurrido un error inesperado, por favor inténtelo de nuevo.";
        }
      )
      .addCase(getServiceOrderTypes.pending, (state) => {
        state.loadingServiceOrderTypes = true;
      })
      .addCase(getServiceOrderTypes.fulfilled, (state, action) => {
        state.loadingServiceOrderTypes = false;
        state.listServiceOrderTypes = action.payload;
      })
      .addCase(
        getServiceOrderTypes.rejected.type,
        (state, action: PayloadAction<AxiosError>) => {
          state.loadingServiceOrderTypes = false;
        }
      )
      .addCase(getCancelReasons.pending, (state) => {
        state.loadingCancellationReasons = true;
        state.cancellationReasons = [];
      })
      .addCase(getCancelReasons.fulfilled, (state, action) => {
        state.loadingCancellationReasons = false;
        if (action.payload) {
          state.cancellationReasons = action.payload;
          action.payload.forEach((item) => {
            if (
              !state.cancellationResponsibilities.includes(item.responsible)
            ) {
              state.cancellationResponsibilities.push(item.responsible);
            }
          });
        }
      })
      .addCase(
        getCancelReasons.rejected.type,
        (state, action: PayloadAction<AxiosError>) => {
          state.loadingCancellationReasons = false;
          state.errorCancellationReasons =
            "No se pudo cargar las razones de cancelación";
        }
      )
      .addCase(cancelServiceOrder.pending, (state) => {
        state.loadingCancelServiceOrder = true;
        state.errorCancelServiceOrder = false;
        state.isDoneCancelServiceOrder = false;
      })
      .addCase(cancelServiceOrder.fulfilled, (state) => {
        state.loadingCancelServiceOrder = false;
        state.isDoneCancelServiceOrder = true;
      })
      .addCase(cancelServiceOrder.rejected, (state) => {
        state.loadingCancelServiceOrder = false;
        state.isDoneCancelServiceOrder = false;
        state.errorCancelServiceOrder = true;
        state.messageErrorCancelServiceOrder =
          "No se pudo cancelar la orden, inténtelo de nuevo.";
      });
  },
});

export const {
  selectOrder,
  setAction,
  cleanStoreServiceOrders,
  resetStatusCancelServiceOrder,
} = serviceOrdersSlice.actions;

export default serviceOrdersSlice.reducer;
